<template>
  <div id="app">
    <el-container>
      <el-header style="display: flex; justify-content: center; align-items: center; height: 60px; background-color: #409EFF; color: #fff; font-size: 24px;">
        <a href="/pdf.pdf" target="_blank" style="color: #fff;">混淆转换对照/点我跳转说明文档</a>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" style="display: flex; justify-content: space-between; align-items: center;">
                <div>
                  <p>请输入请求参数或者响应数据</p>
                  <p>1.支持json</p>
                  <p>2.支持回车换行的文本</p>
                </div>
                <div>
                  <el-select v-model="value" placeholder="请选择" @change="selOnChange">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-button type="text" style="margin-left: 10px;" @click="reqToMapJson">转换</el-button>
                </div>
              </div>
              <el-input
                type="textarea"
                :rows="20"
                v-model="textareaReqParams">
              </el-input>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" style="display: flex; justify-content: space-between; align-items: center;">
                <span>转换前</span>
              </div>
              <div>
                <json-viewer :value="jsonDataReqBefore" :expand-depth=5 copyable></json-viewer>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card">
              <div slot="header" style="display: flex; justify-content: space-between; align-items: center;">
                <span>转换后</span>
              </div>
              <div>
                <json-viewer :value="jsonDataReq" :expand-depth=5 copyable></json-viewer>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
    options: [
        {
          value: '1',
          label: 'PlataListo 混淆'
        }, 
        {
          value: '2',
          label: 'PlataListo 反混淆'
        },
        {
          value: '3',
          label: 'crediplus 混淆'
        },
        {
          value: '4',
          label: 'crediplus 反混淆'
        },
        {
          value: '5',
          label: 'RapiRapa 混淆'
        },
        {
          value: '6',
          label: 'RapiRapa 反混淆'
        },
        {
          value: '7',
          label: 'Andipeso 混淆'
        },
        {
          value: '8',
          label: 'Andipeso 反混淆'
        },
      ],
      value: '1',
      textareaReqParams:"",
      jsonDataReqBefore:{},
      jsonDataReq:{},
      reqFormat:{},
      formatMap:{
        "1":{
            "A":"S", "B":"Q", "C":"B", "D":"R", "E":"J", "F":"C", "G":"V", "H":"W", "I":"U", "J":"X",
            "K":"Z", "L":"N", "M":"O", "N":"D", "O":"I", "P":"H", "Q":"P", "R":"T", "S":"A", "T":"M",
            "U":"F", "V":"L", "W":"K", "X":"Y", "Y":"G", "Z":"E", "a":"y", "b":"q", "c":"d", "d":"m",
            "e":"g", "f":"e", "g":"i", "h":"l", "i":"r", "j":"x", "k":"j", "l":"b", "m":"c", "n":"s",
            "o":"v", "p":"z", "q":"n", "r":"p", "s":"f", "t":"w", "u":"t", "v":"a", "w":"u", "x":"o",
            "y":"h", "z":"k"
        },
        "2":{
          "S":"A", "Q":"B", "B":"C", "R":"D", "J":"E", "C":"F", "V":"G", "W":"H", "U":"I", "X":"J",
          "Z":"K", "N":"L", "O":"M", "D":"N", "I":"O", "H":"P", "P":"Q", "T":"R", "A":"S", "M":"T",
          "F":"U", "L":"V", "K":"W", "Y":"X", "G":"Y", "E":"Z", "y":"a", "q":"b", "d":"c", "m":"d",
          "g":"e", "e":"f", "i":"g", "l":"h", "r":"i", "x":"j", "j":"k", "b":"l", "c":"m", "s":"n",
          "v":"o", "z":"p", "n":"q", "p":"r", "f":"s", "w":"t", "t":"u", "a":"v", "u":"w", "o":"x",
          "h":"y", "k":"z"
        },
        "3":{
          "A":"B","B":"I","C":"G","D":"Z","E":"S","F":"N","G":"K","H":"D","I":"E","J":"X","K":"Q","L":"V","M":"Y","N":"F","O":"J","P":"L","Q":"O","R":"M","S":"P","T":"H","U":"U","V":"T","W":"W","X":"C","Y":"A","Z":"R","a":"o","b":"j","c":"v","d":"i","e":"g","f":"q","g":"z","h":"l","i":"m","j":"p","k":"r","l":"h","m":"d","n":"e","o":"w","p":"s","q":"a","r":"t","s":"k","t":"f","u":"b","v":"x","w":"c","x":"n","y":"y","z":"u"
        },
        "4":{
          "B":"A","I":"B","G":"C","Z":"D","S":"E","N":"F","K":"G","D":"H","E":"I","X":"J","Q":"K","V":"L","Y":"M","F":"N","J":"O","L":"P","O":"Q","M":"R","P":"S","H":"T","U":"U","T":"V","W":"W","C":"X","A":"Y","R":"Z","o":"a","j":"b","v":"c","i":"d","g":"e","q":"f","z":"g","l":"h","m":"i","p":"j","r":"k","h":"l","d":"m","e":"n","w":"o","s":"p","a":"q","t":"r","k":"s","f":"t","b":"u","x":"v","c":"w","n":"x","y":"y","u":"z"
        },
        "5":{
          "A":"L","B":"F","C":"U","D":"G","E":"E","F":"N","G":"M","H":"Q","I":"D","J":"V","K":"I","L":"X","M":"H","N":"K","O":"T","P":"Y","Q":"S","R":"Z","S":"J","T":"C","U":"W","V":"A","W":"O","X":"R","Y":"B","Z":"P","a":"f","b":"h","c":"k","d":"n","e":"u","f":"z","g":"b","h":"l","i":"p","j":"d","k":"i","l":"e","m":"g","n":"c","o":"w","p":"r","q":"q","r":"x","s":"o","t":"j","u":"y","v":"t","w":"v","x":"s","y":"a","z":"m"
        },
        "6":{
          "L":"A","F":"B","U":"C","G":"D","E":"E","N":"F","M":"G","Q":"H","D":"I","V":"J","I":"K","X":"L","H":"M","K":"N","T":"O","Y":"P","S":"Q","Z":"R","J":"S","C":"T","W":"U","A":"V","O":"W","R":"X","B":"Y","P":"Z","f":"a","h":"b","k":"c","n":"d","u":"e","z":"f","b":"g","l":"h","p":"i","d":"j","i":"k","e":"l","g":"m","c":"n","w":"o","r":"p","q":"q","x":"r","o":"s","j":"t","y":"u","t":"v","v":"w","s":"x","a":"y","m":"z"
        },
        "7":{
          "A":"I","B":"Y","C":"L","D":"T","E":"F","F":"K","G":"M","H":"E","I":"U","J":"O","K":"Q","L":"H","M":"A","N":"P","O":"C","P":"N","Q":"J","R":"R","S":"X","T":"S","U":"D","V":"W","W":"G","X":"V","Y":"Z","Z":"B","a":"m","b":"w","c":"i","d":"k","e":"q","f":"v","g":"s","h":"u","i":"n","j":"x","k":"p","l":"z","m":"g","n":"a","o":"h","p":"t","q":"c","r":"r","s":"l","t":"d","u":"o","v":"f","w":"b","x":"e","y":"j","z":"y"
        },
        "8":{
          "I":"A","Y":"B","L":"C","T":"D","F":"E","K":"F","M":"G","E":"H","U":"I","O":"J","Q":"K","H":"L","A":"M","P":"N","C":"O","N":"P","J":"Q","R":"R","X":"S","S":"T","D":"U","W":"V","G":"W","V":"X","Z":"Y","B":"Z","m":"a","w":"b","i":"c","k":"d","q":"e","v":"f","s":"g","u":"h","n":"i","x":"j","p":"k","z":"l","g":"m","a":"n","h":"o","t":"p","c":"q","r":"r","l":"s","d":"t","o":"u","f":"v","b":"w","e":"x","j":"y","y":"z"
        },
        "9":{
          "A":"U","B":"I","C":"Y","D":"X","E":"T","F":"F","G":"G","H":"N","I":"Q","J":"C","K":"J","L":"E","M":"S","N":"P","O":"D","P":"A","Q":"B","R":"H","S":"W","T":"R","U":"K","V":"L","W":"V","X":"O","Y":"M","Z":"Z","a":"g","b":"r","c":"f","d":"y","e":"x","f":"z","g":"a","h":"i","i":"t","j":"b","k":"e","l":"j","m":"u","n":"k","o":"o","p":"v","q":"l","r":"s","s":"w","t":"n","u":"c","v":"d","w":"h","x":"p","y":"m","z":"q"
        },
        "10":{
          "U":"A","I":"B","Y":"C","X":"D","T":"E","F":"F","G":"G","N":"H","Q":"I","C":"J","J":"K","E":"L","S":"M","P":"N","D":"O","A":"P","B":"Q","H":"R","W":"S","R":"T","K":"U","L":"V","V":"W","O":"X","M":"Y","Z":"Z","g":"a","r":"b","f":"c","y":"d","x":"e","z":"f","a":"g","i":"h","t":"i","b":"j","e":"k","j":"l","u":"m","k":"n","o":"o","v":"p","l":"q","s":"r","w":"s","n":"t","c":"u","d":"v","h":"w","p":"x","m":"y","q":"z"
        }
      }
    }
  },
  computed:{
    
  },
  created(){
    this.reqFormat = this.formatMap[this.value]
  },
  methods:{
    selOnChange(value){
      this.reqFormat = this.formatMap[value]
    },
    reqToMapJson(){
      var map = {}
      if(this.isValidJSON(this.textareaReqParams)){
        map = JSON.parse(this.textareaReqParams)
      }else{
        map = this.strArrToMap(this.textareaReqParams)
      }
      var that = this

      var newMap = this.traverseNew(map, function(key){
        return that.getReqFormatStr(key)
      })
      this.jsonDataReqBefore = map
      this.jsonDataReq = newMap
    },
    getReqFormatStr(str){
      var resStr = str.trim()
      let strArr = [];
      for (const char of resStr) {
        let isSet = false
        for (const key in  this.reqFormat) {
          if (Object.prototype.hasOwnProperty.call(this.reqFormat, key)) {
            const value =  this.reqFormat[key];
            if(key === char){
              isSet = true
              strArr.push(value)
              break
            }
          }
        }
        if(!isSet){
          strArr.push(char)
        }
      }
      return strArr.join("")
    },
    strArrToMap(str){
      if(!str){
        return {}
      }
      const paramsList = this.textareaReqParams.split(/\n/);
      if(!paramsList || paramsList.length == 0){
        return {}
      }
      var map = {}
      paramsList.forEach((item)=>{
        if(item){
          map[item] = item
        }
      })
      return map
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    traverseNew(obj,replacer) {
      var newObj = {}
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (Array.isArray(obj[key])) {
            let list = []
            for (let i = 0; i < obj[key].length; i++) {
              if (typeof obj[key][i] === 'object' && obj[key][i] !== null) {
                list.push(this.traverseNew(obj[key][i], replacer))
              }
            }
            newObj[replacer(key)] = list;
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            newObj[replacer(key)] = this.traverseNew(obj[key],replacer);
          } else {
            newObj[replacer(key)] = obj[key];
          }
        }
      }
      return newObj;
    }

  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,body{
  font-family:Arial,Helvetica,sans-serif;
  color: #333;
  font-size: 14px;
}
</style>
