import Vue from 'vue';
import ElementUI from 'element-ui';
import JsonViewer from 'vue-json-viewer';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

Vue.use(ElementUI);
Vue.use(JsonViewer);

new Vue({
  el: '#app',
  render: h => h(App)
});